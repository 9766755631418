import {getRasterLayerBase} from "../../../../utils/layers/rasterIntialLayers";
import {getMapBoxResourceId, setRasterLayerToMap} from "../../../../utils/layers/mapUtils";
import {put, select} from "redux-saga/effects";
import {exchangeRasterLayerOrderAction, setRasterLayerData} from "../../../slices/layers/rasterLayersSlice";

export function* createRasterLayerHandler(action) {
  try {
    const rasterLayers = action.payload.data
    let layerArray = []
    let cogUrl = null;
    if (rasterLayers.length > 0) {
      for (const rasterLayer of rasterLayers) {
        const rasterLayerData = getRasterLayerBase(rasterLayer.type)
        const layerId = rasterLayerData?.name && getMapBoxResourceId(rasterLayerData.name, rasterLayer.flight, true)
        // if(layerId)
        const projectLayer =  yield select(state => state.rasterLayers.hasOwnProperty(layerId) && state.rasterLayers[layerId])
        if (!!projectLayer) {
          layerArray.push(projectLayer)
        } else {
          if(rasterLayer.mapbox_tile_id) {
            rasterLayerData.mapboxTileIdKey = rasterLayer.mapbox_tile_id
            rasterLayerData.flightId = rasterLayer.flight
            layerArray.push(rasterLayerData)
          } else {
            if(rasterLayer?.type === 'ortho_cog') {
              rasterLayerData.cogUrl = rasterLayer.uri;
              rasterLayerData.flightId = rasterLayer.flight;
              layerArray.push(rasterLayerData);
            }
          
          }
          
        }
      }
      const sortedLayers = layerArray.sort((a, b) => a.order - b.order)
      for (const layer of sortedLayers) {
        window.map && setRasterLayerToMap(window.map, layer, layer.flightId, layer.layout.visibility, layer.cogUrl)
        const layerId = getMapBoxResourceId(layer.name, layer.flightId, true)
        yield put(setRasterLayerData({layerKey: layerId, layerData: layer}))
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* rearrangeRasterLayersHandler(action) {
  try {
    const destinationLayer = action.payload.destinationLayer
    const sourceLayer = action.payload.sourceLayer
    const selectFlightId = yield select(state => state.flightSelected.id)
    yield put(exchangeRasterLayerOrderAction({sourceLayer, destinationLayer}))
    const rasterLayers = yield select(state => state.rasterLayers)
    const sortedRasterLayers = Object.keys(rasterLayers).filter(layerKey => (layerKey.includes(selectFlightId))).sort((a, b) => rasterLayers[a].order - rasterLayers[b].order)
    for (const layer of sortedRasterLayers) {
      window.map && window.map.moveLayer(layer, sortedRasterLayers[sortedRasterLayers.length - 1])
    }
  } catch (error) {
    console.log(error)
  }
}