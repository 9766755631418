import { call, put } from "redux-saga/effects";
import { seeListRequest } from "../requests/seeRequests";
import { setSeeListDataAction } from "../../slices/see/list";
import { createRasterLayerAction } from "../../slices/layers/rasterLayersSlice";

export function* seeListHandler(action) {
  try {
    const flightId = action.payload.flightId;
    const response = yield call(seeListRequest, flightId);
    yield put(setSeeListDataAction({ data: response.data }));
    yield put(createRasterLayerAction({ data: response.data }));
  } catch (error) {
    console.log(error);
  }
}
