import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Check, Clear } from '@mui/icons-material';
import styled from 'styled-components';
import Offerbox from './offerbox';
import Header from '../header';



const CustomTableCell = styled(TableCell)`
  && {
    color: #000;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.443px;
  }
`;


const Accountplan = () => {
  const column1Data = ['Create Projects', 'Add Flight', 'Precise Flights (GCP/RTK)',
   'See', 'Measure', 'Smart Tracking', 'Reports', 'Design File'];
  const column2Data = [true, false, true, false, true, false, true, false, true];
  const column3Data = [false, true, false, true, false, true, false, true, false];
  const column4Data = [true, true, false, false, true, true, false, false, true];

 
  return (
    <div style={{ backgroundColor: "" }}>
    {/* header */}
    <Header />
  <div style={{ display: 'flex', padding: '0px 150px 0px 150px', marginTop: '-100px'}}>
  <TableContainer component={Paper}>
      <Table>
        <TableHead>
        <TableRow>
            <CustomTableCell style={{ borderLeft: 'none', borderRight: 'none' }}> Features</CustomTableCell>
            <CustomTableCell style={{ borderRight: 'none', display: 'flex', justifyContent: 'right' }}>Mapper</CustomTableCell>
            <CustomTableCell colSpan={2} style={{ borderRight: 'none' }} sx={{
          textAlign: 'right', 
        }}>
          Pro Surveyor
            </CustomTableCell>
           
            <CustomTableCell style={{ borderRight: 'none' }}>
            </CustomTableCell>
          </TableRow>

          <TableRow>
            <CustomTableCell style={{ borderLeft: 'none', borderRight: 'none' }}></CustomTableCell>
            <CustomTableCell style={{ borderRight: '1px solid #e0e0e0' }}></CustomTableCell>
            <CustomTableCell colSpan={2} style={{ borderRight: 'none' }}>
            
            <Offerbox 
                title="$000/Month" 
                descriptionText="CAD $0000/Year - For Canadian Citizen" 
            />
            </CustomTableCell>
           
            <CustomTableCell style={{ borderRight: 'none' }}>
            <Offerbox 
                title="$000/Month" 
                descriptionText="CAD $0000/Year - For Canadian Citizen" 
            />


            </CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {column1Data.map((item, index) => (
            <TableRow key={item}>
              <CustomTableCell style={{ borderLeft: 'none' }}>{item}</CustomTableCell>
              <TableCell style={{ borderRight: '1px solid #e0e0e0' }}>
                {column2Data[index] ? <Check /> : <Clear />}
              </TableCell>
              <TableCell style={{ borderRight: 'none' }}>
                {/* {column3Data[index] ? <Check /> : <Clear />} */}
              </TableCell>
              <TableCell style={{ borderRight: 'none' }} sx={{
          textAlign: 'right', 
        }}>
                {column4Data[index] ? <Check /> : <Clear />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
</div>
      
    </div>
  );
};

export default Accountplan;
