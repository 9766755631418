import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import {getIconComponentByType, getIconComponentByTypeWithProps} from "../../../utils/assets";
import {useEffect, useState} from "react";
import SmartTrackingMaterialSelect from "./MaterialSelect";
import {useDispatch, useSelector} from "react-redux";
import {getSmartTrackingPolygonListAction} from "../../../redux/slices/smartTracking/list";
import _ from "lodash";
import {getGeoJsonLayerBase} from "../../../utils/layers/initialGeoJsonLayers";
import {getMapBoxResourceId, setMapLayerLayoutProperty} from "../../../utils/layers/mapUtils";
import {updateGeoJsonLayerVisibilityAction} from "../../../redux/slices/layers/geoJsonLayersSlice";

const buttonStyle = {
  padding: 0,
  width: 31,
  height: 23
}

const SmartTrackingButtons = () => {
  const [layerVisibility, setLayerVisibility] = useState(true);
  const dispatch = useDispatch()

  const selectedFlightId = useSelector(state => state.flightSelected.id)

  useEffect(() => {
    if (_.isUndefined(window.map)) return
    const visibility = layerVisibility === true ? "visible" : "none"
    const baseGeoJsonLayerData = getGeoJsonLayerBase("tracking_polygons")
    Object.keys(baseGeoJsonLayerData.subLayers).forEach(subLayerKey => {
      const layerId = getMapBoxResourceId(`${baseGeoJsonLayerData.name}-${subLayerKey}`, selectedFlightId, true)
      if(setMapLayerLayoutProperty(layerId, "visibility", visibility)) dispatch(updateGeoJsonLayerVisibilityAction({key: layerId, visibility: visibility}))
    })
  }, [layerVisibility])

  return (
    <Grid container justifyContent="space-evenly">
      <Grid item xs={12} md={8}>
        <Paper variant="outlined">
          <SmartTrackingMaterialSelect />
        </Paper>
      </Grid>
      <Grid item xs={12} md={1.65}>
        <Paper variant="outlined">
          <IconButton sx={buttonStyle} color="primary" onClick={() => setLayerVisibility(!layerVisibility)}>
            {layerVisibility ? getIconComponentByType("eyeOpen") : getIconComponentByTypeWithProps("eyeClose")({
              color: "secondary"
            })}
            {}
          </IconButton>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default SmartTrackingButtons