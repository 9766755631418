import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  getIconComponentByType,
  getIconComponentByTypeWithProps,
} from "../../../utils/assets";
import IconButton from "@mui/material/IconButton";
import FilterMeasurementMenu from "../Menu/FilterMeasurementMenu";

const FilterMeasurementButton = ({ buttonStyle }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [shouldCloseMenu, setShouldCloseMenu] = useState(true); 

  const measurementData = useSelector(
    (state) => state.measurementList.filters
  );
  

  useEffect(() => {
    setShouldCloseMenu(measurementData?.length === 0);
  }, [measurementData]);

  const handleToggleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleToggleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton
        sx={buttonStyle}
        color="primary"
        onClick={handleToggleMenuOpen}
      >
        {shouldCloseMenu
          ? getIconComponentByType("filterAltOffIcon")
          : getIconComponentByTypeWithProps("filter")({
              color: "primary",
            })}
      </IconButton>
      <FilterMeasurementMenu
        handleToggleMenuClose={handleToggleMenuClose}
        anchorEl={anchorEl}
      />
    </>
  );
};

export default FilterMeasurementButton;
