import {createSlice} from "@reduxjs/toolkit";

const rasterLayersSlice = createSlice({
  name: "rasterLayersState",
  initialState: {},
  reducers: {
    createRasterLayerAction() {
    },
    rearrangeRasterLayersAction() {
    },
    setRasterLayerData(state, action) {
      return {
        ...state,
        [action.payload.layerKey]: {...action.payload.layerData}
      }
    },
    exchangeRasterLayerOrderAction(state, action) {
      return {
        ...state,
        [action.payload.sourceLayer]: {...state[action.payload.sourceLayer], order: state[action.payload.destinationLayer].order},
        [action.payload.destinationLayer]: {...state[action.payload.destinationLayer], order: state[action.payload.sourceLayer].order}
      }
    },
    updateRasterLayerTransparencyAction(state, action) {
      return {
        ...state,
        [action.payload.key]: {...state[action.payload.key], paint: {"raster-opacity": action.payload.opacity}}
      }
    },
    updateRasterLayerVisibilityAction(state, action) {
      return {
        ...state,
        [action.payload.key]: {...state[action.payload.key], layout: {visibility: action.payload.visibility}}
      }
    }
  }
})

export const {
  createRasterLayerAction,
  setRasterLayerData,
  rearrangeRasterLayersAction,
  exchangeRasterLayerOrderAction,
  updateRasterLayerTransparencyAction,
  updateRasterLayerVisibilityAction
} = rasterLayersSlice.actions

export default rasterLayersSlice.reducer