import {createSlice} from "@reduxjs/toolkit";

const mapSlice = createSlice({
  name: "mapState",
  initialState: {
    lng: "-106.81",
    lat: "53.69",
    zoom: "3.00",
    mapView: true
  },
  reducers: {
    setMapStateAction(state, action) {
      return {
        ...state,
       ...action.payload
      }
    },
    setMapViewAction(state, action){
      return {
        ...state,
        mapView: action.payload.mapView
      }
    }
  }
})

export const {setMapStateAction, setMapViewAction} = mapSlice.actions

export default mapSlice.reducer
