import { call, put } from "redux-saga/effects";
import {
  googleLoginRequest,
  loginRequest,
  logoutRequest,
  userRecordRequest,
} from "../../requests/basic/loginRequest";
import { setLoginServerErrorsAction } from "../../../slices/basic/loginSlice";
import {
  logoutSuccess,
  logoutFailure,
} from "../../../slices/basic/LogoutSlice";
export function* googleLoginHandler(action) {
  try {
    const response = yield call(googleLoginRequest, action.payload);
    const { key } = response.data;
    localStorage.setItem("token", key);
    if (key) {
      const userRecord = yield call(userRecordRequest);
      const { first_name, last_name, email} = userRecord.data;
      localStorage.setItem("name", `${first_name} ${last_name}`);
      localStorage.setItem("userEmal", email);

    }
    localStorage.setItem("isAuthenticated", "true");
    window.location.reload();

  } catch (error) {
    console.log({ ...error });
  }
}

export function* loginHandler(action) {
  try {
    const response = yield call(loginRequest, action.payload);
    const sessionKey = response.data.key;
    localStorage.setItem("token", sessionKey);
    if (sessionKey) {
      const userRecord = yield call(userRecordRequest);
      const { first_name, last_name, email } = userRecord.data;
      localStorage.setItem("name", `${first_name} ${last_name}`);
      localStorage.setItem("userEmail", email);

    }
    localStorage.setItem("isAuthenticated", "true");

    window.location.reload();
  } catch (error) {
    if (error.response.status !== 500)
      yield put(setLoginServerErrorsAction({ errors: error.response.data }));
    console.log({ ...error });
  }
}

export function* logoutHandler() {
  try {
    // yield put(logoutRequest()); // dispatch the logout request action
    yield call(logoutRequest); // call the logout API
    yield put(logoutSuccess()); // dispatch the logout success action
    localStorage.removeItem("token");
    localStorage.removeItem("isAuthenticated", "false");
    localStorage.removeItem("name", null);
    // window.location.reload();
  } catch (error) {
    yield put(logoutFailure(error)); // dispatch the logout failure action
  }
}
