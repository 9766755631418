import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Grid } from "@mui/material";
import styled from 'styled-components';
import Export from "components/Export";
import { Link } from "react-router-dom";
import PrivateRequestBase from "redux/sagas/requests/rootRequest/PrivateRequestBase";
import { stockpileReportAction }  from 'redux/slices/report';

const StyledLink = styled(Link)`
  margin-left: 2px;
  margin-top: 5px;
`;
const DownloadReportPage = () => {
  const [reportData, setreportData] = useState();
  const selectedFlightId = useSelector((state) => state.flightSelected.uuid);
  const stockpileData = useSelector((state) =>  state.reportData.data );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(stockpileReportAction());
  },[])

  useEffect(() => {
    PrivateRequestBase("get", `api/generate_report/${selectedFlightId}`)
    .then((response) => {
      if (response.status  !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response;
    })
    .then((result) => {
      setreportData(result?.data);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  }, [selectedFlightId]);

  const handleOpenNewTab = () => {
    window.open('/displayReport', '_blank');
  }

  const handleStockpileReport = () => {
    window.open('/stockpileReport', '_blank');
  }
  
  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        pt={2}
        pb={2}
      >
        <Typography variant="h4">Report</Typography>
      </Box>
      <Divider />
      <Grid
        container
        direction="column"
        spacing={1}
        sx={{
          padding: "8px 8px 8px",
        }}
      >

      <Grid item sx={{
          padding: "8px 8px 8px",
        }}>
          <Export
            tittle="Create Report"
            avatarIcon="PrintIcon"
            onSave={handleOpenNewTab}
            isDisabled={reportData?.message === 'Polygon not found' ? true : false}
          />
        </Grid>
      <Grid item sx={{
          padding: "8px 8px 8px",
        }}>
          <Export
            tittle="Stockpile Report"
            avatarIcon="PrintIcon"
            onSave={handleStockpileReport}
            isDisabled={stockpileData?.stockpile_info?.length ? false : true}
          />
        </Grid>
        </Grid>
    </>
  );
};

export default DownloadReportPage;
