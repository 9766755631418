import { getGeoJsonLayerBase } from "../../../../utils/layers/initialGeoJsonLayers";
import { getMapBoxResourceId, setGeoJsonLayerToMap, getMapMapLayers } from "../../../../utils/layers/mapUtils";
import { call, put, select } from "redux-saga/effects";
import { setupMeasurementGeoJson, setupSmartTrackingGeoJson } from "../../helpers/measurementHelper";
import {
  createGeoJsonLayersAction,
  updateGeoJsonLayerDataAction,
  updateGeoJsonLayerItemSelectAction
} from "../../../slices/layers/geoJsonLayersSlice";
import { createMeasurementProfileRequest } from "../../requests/measurements";
import { formatMeasurementProfileDataAction } from "../../../slices/measurement/profiler";

export function* createMeasurementLayerHandler(action) {
  try {
    const data = action.payload.data;
    const selectedFlightId = action.payload.selectedFlightId;
    const geoJsonLayers = yield select(state => state.geoJsonLayers)

    const flightId = yield select(state => state.flightSelected.id)

    for (const sublayerKey of Object.keys(getGeoJsonLayerBase("measurement").subLayers)) {
      const geoJsonLayerInfo = getGeoJsonLayerBase("measurement", sublayerKey)

      const layerId = getMapBoxResourceId(`${geoJsonLayerInfo.name}-${sublayerKey}`, flightId, true)
      if (geoJsonLayers.hasOwnProperty(layerId)) {
        const geoJson = setupMeasurementGeoJson(data.filter(item => item.geometry.type === sublayerKey), sublayerKey)
        geoJsonLayerInfo.subLayers[sublayerKey].data = geoJson
        // if (removeLayers) removeMapLayer(layerId)
        yield put(updateGeoJsonLayerDataAction({
          key: layerId,
          sublayerKey: sublayerKey,
          data: geoJson
        }))
        // geoJsonLayerInfo.subLayers[sublayerKey].data = measurementSource[sublayerKey]
        //geoJsonLayers[layerId].layout.visibility -> for visibility set
        window.map && setGeoJsonLayerToMap(window.map, geoJsonLayerInfo, flightId, geoJsonLayers[layerId].layout.visibility, sublayerKey)
      } else {
        geoJsonLayerInfo.subLayers[sublayerKey].data = setupMeasurementGeoJson(data.filter(item => item.geometry.type === sublayerKey), sublayerKey)
        window.map && setGeoJsonLayerToMap(window.map, geoJsonLayerInfo, flightId, "visible", sublayerKey)
        yield put(createGeoJsonLayersAction({layerKey: layerId, layerData: geoJsonLayerInfo}))
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* measurementItemSelectHandler(action) {
  try {
    const selectedFlightId = yield select(state => state.flightSelected.id)
    const sublayerKey = action.payload.type === 0 ? "Point" : (action.payload.type === 1 ? "LineString" : "Polygon")
    const geoJsonLayerInfo = getGeoJsonLayerBase("measurement", sublayerKey)
    const layerId = getMapBoxResourceId(`${geoJsonLayerInfo.name}-${sublayerKey}`, selectedFlightId, true)
    // getMapMapLayers();
    yield put(updateGeoJsonLayerItemSelectAction({
      key: layerId,
      layerType: sublayerKey,
      id: action.payload.id,
      selected: action.payload.selected
    }))

  } catch (error) {
    console.log(error)
  }
}


export function* measureProfileHandler(action) {
  try {
      const profileResponse = yield call(createMeasurementProfileRequest, action.payload.id)
      yield put(formatMeasurementProfileDataAction({ data: profileResponse.data }))
  } catch (error) {
    console.log(error)
  }
}

export function* removeMeasureProfileHandler(action) {
  try {
      yield put(formatMeasurementProfileDataAction({data: null}))

  } catch (error) {
    console.log(error)
  }
}


export function* createSmartTrackingPolygonLayerHandler(action) {
  try {
    const data = action.payload.data
    const geoJsonLayers = yield select(state => state.geoJsonLayers)

    console.log(data)

    const flightId = yield select(state => state.flightSelected.id)
    for (const sublayerKey of Object.keys(getGeoJsonLayerBase("tracking_polygons").subLayers)) {
      const geoJsonLayerInfo = getGeoJsonLayerBase("tracking_polygons", sublayerKey)

      const layerId = getMapBoxResourceId(`${geoJsonLayerInfo.name}-${sublayerKey}`, flightId, true)
      if (geoJsonLayers.hasOwnProperty(layerId)) {
        const geoJson = setupSmartTrackingGeoJson(data)
        geoJsonLayerInfo.subLayers[sublayerKey].data = geoJson
        // if (removeLayers) removeMapLayer(layerId)
        yield put(updateGeoJsonLayerDataAction({
          key: layerId,
          sublayerKey: sublayerKey,
          data: geoJson
        }))
        // geoJsonLayerInfo.subLayers[sublayerKey].data = measurementSource[sublayerKey]
        //geoJsonLayers[layerId].layout.visibility -> for visibility set
        window.map && setGeoJsonLayerToMap(window.map, geoJsonLayerInfo, flightId, geoJsonLayers[layerId].layout.visibility, sublayerKey)
      } else {
        geoJsonLayerInfo.subLayers[sublayerKey].data = setupSmartTrackingGeoJson(data)
        window.map && setGeoJsonLayerToMap(window.map, geoJsonLayerInfo, flightId, "visible", sublayerKey)
        yield put(createGeoJsonLayersAction({layerKey: layerId, layerData: geoJsonLayerInfo}))
      }
    }

  } catch (error) {
    console.log(error)
  }
}