import { createSlice } from "@reduxjs/toolkit";

const measurementListSlice = createSlice({
  name: "measurementList",
  initialState: {
    loading: false,
    data: [],
    selectedFlightId: null,
    filters: {
      type: "all",
      sort: "nameAsc",
    },
  },
  reducers: {
    getMeasurementListAction(state, action) {
      return {
        ...state,
        loading: true,
        data: [],
      };
    },
    setMeasurementFilterTypeAction(state, action) {
      const { type } = action.payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          type: type, 
        },
      };
    },      
    resetMeasurementFiltersAction(state, action) {
      return {
        ...state,
        filters: {
          type: [
    "Polygon",
    "LineString",
    "Point"
],
          sort: 'nameAsc',
        },
      };
    },
    setMeasurementSortTypeAction(state, action) {
      return {
        ...state,
        filters: { ...state.filters, sort: action.payload.type },
      };
    },
    handleMeasurementListDataAction() {},
    setMeasurementListDataAction(state, action) {
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        selectedFlightId: action.payload.selectedFlightId
      };
    },
    updateMeasurementProfilerDataAction(state, action) {
      return {
        ...state,
        profilerData: action.payload.data,
      };
    },

    updateMeasurementProfilerLatLonAction(state, action) {
      return {
        ...state,
        profilerLatLon: action.payload.data,
      };
    },
    createSocketConnectionAction() {},
    updateMeasureDataListAction() {},
    downloadMeasurementGeoJsonAction() {},
  },
});

export const {
  getMeasurementListAction,
  setMeasurementListDataAction,
  setMeasurementFilterTypeAction,
  setMeasurementSortTypeAction,
  handleMeasurementListDataAction,
  createSocketConnectionAction,
  updateMeasureDataListAction,
  downloadMeasurementGeoJsonAction,
  updateMeasurementProfilerDataAction,
  updateMeasurementProfilerLatLonAction,
  resetMeasurementFiltersAction
} = measurementListSlice.actions;

export default measurementListSlice.reducer;
