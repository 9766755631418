import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getSmartTrackingPolygonListAction,
  setSmartTrackingPolygonDataAction,
} from "../../redux/slices/smartTracking/list";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SmartTrackingPolygonItem from "./Item";
import isNull from "lodash/isNull";


const SmartTrackingList = () => {
  const polygons = useSelector((state) => {
    return state.smartTrackingList.data
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSmartTrackingPolygonListAction());
  }, []);

  return polygons?.length > 0 ? (
    <Grid
      container
      direction="column"
      spacing={1}
      sx={{
        padding: "8px 8px 8px",
      }}
    >
      {polygons.map((item) => (
        <Grid item key={item.id}>
          <SmartTrackingPolygonItem polygon={item} />
        </Grid>
      ))}
    </Grid>
  ) : (
    <Typography align="center" mt={4}>
      No Tracking Found
    </Typography>
  );
};

export default SmartTrackingList;
