import Map from "../../Map";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import ProjectMainContent from "./ProjectMainContent";
import { setMapViewAction } from "../../../redux/slices/mapbox/mapSlice";
import {
  createProjectMarkerAction,
  getProjectListAction,
} from "../../../redux/slices/project/list";
import { hideAllLayersAction } from "../../../redux/slices/layers";
import WithMapContent from "./WithMapContent";
import { removeSelectedProjectAction } from "../../../redux/slices/project/selected";
import Invoice from "../../Account/Invoice";
import AccountPlan from "../../Account/AccountPlan";

// const MainContent = () => {
//   const location = useLocation();
//   const dispatch = useDispatch();
//   const history = useHistory();

//   const mapView = useSelector((state) => state.mapState.mapView);
//   const projectMapView = useSelector((state) => state.projectList.mapView);
//   useEffect(() => {
//     if (location.pathname === "/project") {
//       dispatch(getProjectListAction());
//       dispatch(removeSelectedProjectAction());
//       dispatch(setMapViewAction({ mapView: projectMapView }));
//       if (projectMapView) {
//         window.map._markers.forEach((marker) => marker.remove());
//         dispatch(createProjectMarkerAction({ history: history }));
//         dispatch(hideAllLayersAction());
//       }
//     }
//   });

//   const renderComponent = () => {
//     if (location.pathname === "/project") {
//       return !mapView && <ProjectMainContent />;
//     }
//     dispatch(setMapViewAction({ mapView: true }));
//   };

//   return (
//     <>
//       <Box
//         style={{ width: "100%" }}
//         sx={{
//           display: mapView ? "block" : "none",
//         }}
//       >
//         <Map />
//       </Box>
//       {!mapView ? renderComponent() : <WithMapContent />}
//     </>
//   );
// };

const MainContent = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const mapView = useSelector((state) => state.mapState.mapView);
  const projectMapView = useSelector((state) => state.projectList.mapView);

  useEffect(() => {
    if (location.pathname === "/project") {
      dispatch(getProjectListAction());
      dispatch(removeSelectedProjectAction());
      dispatch(setMapViewAction({ mapView: projectMapView }));
    }
  }, [location.pathname, projectMapView, dispatch]);

  useEffect(() => {
    if (location.pathname === "/invoice") {
      // dispatch(removeSelectedProjectAction());
      dispatch(setMapViewAction({ mapView: false }));
    }
  }, [location.pathname, projectMapView, dispatch]);

  useEffect(() => {
    if (projectMapView) {
      window.map._markers.forEach((marker) => marker.remove());
      dispatch(createProjectMarkerAction({ history: history }));
      dispatch(hideAllLayersAction());
    }
  }, [projectMapView, dispatch, history]);

  const renderComponent = () => {
    if (location.pathname === "/project") {
      return !mapView && <ProjectMainContent />;
    }
    if (location.pathname === "/invoice") {
      return !mapView && <Invoice />;
    }

        dispatch(setMapViewAction({ mapView: true }));
  };

  return (
    <>
      <Box
        style={{ width: "100%" }}
        sx={{
          display: mapView ? "block" : "none",
        }}
      >
        <Map />
      </Box>
      {!mapView ? renderComponent() : <WithMapContent />}
    </>
  );
};

export default MainContent;
