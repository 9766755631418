import CardHeader from "@mui/material/CardHeader";
import { Link, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import {
  getIconComponentByType,
  getIconComponentByTypeWithProps,
} from "../../utils/assets";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import { formatDate } from "../../utils/formattings";
import Card from "@mui/material/Card";
import { useState } from "react";
import FlightAction from "./Actions";
import { useDispatch } from "react-redux";
import { selectFlightAction } from "../../redux/slices/flight/selected";
import { cut } from "utils/layers/mapUtils";

const FlightItem = ({ flight, isLast }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const selectFlight = () => {
    dispatch(selectFlightAction({ flight: flight }));
  };

  const handleToggleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card sx={{ width: "100%" }} raised>
        <CardHeader
          component={Link}
          sx={{
            padding: "5px 5px 5px 16px",
            cursor: "pointer",
          }}
          onClick={selectFlight}
          action={
            <Box display="inline-flex" alignItems="center">
              {!flight.processed ? (
                <CircularProgress size={22} />
              ) : (
                <Tooltip title="Completed">
                  {getIconComponentByTypeWithProps("checkCircleOutlined")({
                    color: "secondary",
                  })}
                </Tooltip>
              )}

              <IconButton onClick={handleToggleMenuOpen} aria-label="settings">
                {getIconComponentByType("moreVert")}
              </IconButton>
            </Box>
          }
          title={<Tooltip title={flight?.name}><span>{cut(flight?.name, 22)}</span></Tooltip>}
          style={{ textTranform: "capitalize", textDecoration: "none" }}
          subheader={formatDate(flight.flown_date, "dd-mm-yyyy")}
        />
      </Card>
      <FlightAction
        flight={flight}
        anchorEl={anchorEl}
        handleToggleMenuClose={handleToggleMenuClose}
        isLast={isLast}
      />
    </>
  );
};

export default FlightItem;
