import React, { useEffect, useState } from "react";
import Export from "../../components/Export";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import {
  saveDSMExportAction,
  saveMeasurementExportAction,
  saveOrthoExportAction,
  saveTerrainExportAction,
  saveExportExcelAction,
  saveExportSmartTrackingAction
} from "../../redux/slices/export";
import PrivateRequestBase from "redux/sagas/requests/rootRequest/PrivateRequestBase";
import { useSelector } from "react-redux";

const ExportPage = () => {
  const dispatch = useDispatch();
  const selectedFlightId = useSelector((state) => state.flightSelected.uuid);
  const [exportData, setexportData] = useState();

  useEffect(() => {
    PrivateRequestBase("get", `api/check_exports/${selectedFlightId}`)
    .then((response) => {
      if (response.data?.status  !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response;
    })
    .then((result) => {
      setexportData(result?.data);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  }, [selectedFlightId]);

  const onOrthoSave = () => {
    dispatch(saveOrthoExportAction());
  };

  const onTerrainSave = () => {
    dispatch(saveTerrainExportAction());
  };

  const onDsmSave = () => {
    dispatch(saveDSMExportAction());
  };

  const onMeasureSave = () => {
    dispatch(saveMeasurementExportAction());
  };

  const onSmartTrackingSave = () => {
    dispatch(saveExportSmartTrackingAction());

  }

  const onMeasureExcelSheet = () => {
    dispatch(saveExportExcelAction());
  }

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        pt={2}
        pb={2}
      >
        <Typography variant="h4">Export</Typography>
      </Box>
      <Divider />
      <Grid
        container
        direction="column"
        spacing={1}
        sx={{
          padding: "8px 8px 8px",
        }}
      >
        <Grid item>
          <Export
            tittle="Ortho Photo (tif)"
            avatarIcon="imageIcon"
            onSave={onOrthoSave}
            isDisabled={false}
          />
        </Grid>
        <Grid item>
          <Export
            tittle="Hillshade (tif)"
            avatarIcon="terrain"
            onSave={onTerrainSave}
            isDisabled={false}
          />
        </Grid>
        <Grid item>
          <Export
            tittle="Surface Model (tif)"
            avatarIcon="straightenIcon"
            onSave={onDsmSave}
            isDisabled={false}
          />
        </Grid>
        <Grid item>
          <Export
            tittle="Measurement (kmz)"
            avatarIcon="straightenIcon"
            onSave={onMeasureSave}
            isDisabled={exportData?.measurements !== 0 ? false : true}
          />
        </Grid>
        <Grid item>
          <Export
            tittle="Smart Tracking (kml)"
            avatarIcon="straightenIcon"
            onSave={onSmartTrackingSave}
            isDisabled={exportData?.smart_tracking !== 0 ? false : true}
          />
        </Grid>
        <Grid item>
          <Export
            tittle="Measurement (xlsx)"
            avatarIcon="straightenIcon"
            onSave={onMeasureExcelSheet}
            isDisabled={exportData?.measurements !== 0 ? false : true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ExportPage;
