import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  getMeasurementListAction,
  updateMeasureDataListAction,
  resetMeasurementFiltersAction
} from "../../redux/slices/measurement/list";
import { measurementDeleteAction } from "../../redux/slices/measurement/delete";
import { resetCreateMeasureAction } from "../../redux/slices/measurement/create";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MeasurementItem from "./Item";
import PrivateRequestBase from "../../redux/sagas/requests/rootRequest/PrivateRequestBase";


const MeasurementList = () => {

  const measurementData = useSelector(
    (state) => state.measurementList.data
  );


  const selectedFlightUuid = useSelector((state) => state.flightSelected.uuid);
  const selectedFlightId = useSelector((state) => state.flightSelected.id);
  const flights = useSelector((state) => state.flightList.data);
  const flightSelectedId = useSelector((state) => state.flightSelected.id);
  const [isMeasurementDone, setisMeasurementDone] = useState(false); 

  const [data, setData] = useState(null);
  const measurementUuid = measurementData?.filter((measure) => measure.processed !== true)?.[0]?.uuid; 
  const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/events/measurement/${measurementUuid}`;

  
  const dispatch = useDispatch();
  useEffect(() => {
    if (flightSelectedId === null || flightSelectedId === undefined) {
      // Remove markers if no flight is selected
      dispatch(measurementDeleteAction());
    }
  }, [dispatch, flightSelectedId]);


  useEffect(() => {
    dispatch(resetCreateMeasureAction())
    dispatch(getMeasurementListAction())
    dispatch(resetMeasurementFiltersAction())
  }, [dispatch]);

  

  useEffect(() => {
    let fetchDataInterval;

    const fetchData = async () => {
      try {
        const open_socket =  measurementData.filter((measure) => measure.processed !== true); 
        clearInterval(fetchDataInterval);
        if(open_socket.length) {
        const response = await PrivateRequestBase("get", apiUrl);
        setData(response);
        if (response?.data?.data?.uuid) {
          dispatch(updateMeasureDataListAction({ data: response?.data?.data }));
          dispatch(getMeasurementListAction());
          // dispatch(resetCreateMeasureAction())
          setisMeasurementDone(true); 
          clearInterval(fetchDataInterval);
        }
      }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const isProcessed =  measurementData?.length > 0 && measurementData?.filter((measure) => measure.processed !== true)

    if (!isMeasurementDone && isProcessed.length) {
      fetchDataInterval = setInterval(fetchData, 5000);
    }

    return () => clearInterval(fetchDataInterval);
  }, [selectedFlightUuid, dispatch, measurementData, apiUrl, isMeasurementDone, data, measurementUuid]);

  return flights.length > 0 && measurementData?.length > 0 ? (
    <Grid
      container
      direction="column"
      style={{ boxShadow: "none", marginBottom: 10 }}
      //spacing={1}
      // sx={{
      //   padding: "8px 8px 8px",
      // }}
    >
      {measurementData?.map((item) => (
        <Grid item key={item.id} style={{ width: "100%" }}>
          <MeasurementItem item={item} selectedFlightId={selectedFlightId} isMeasurementDone={isMeasurementDone} />
        </Grid>
      ))}
    </Grid>
  ) : (
    <Typography align="center" mt={4}>
      No Measurement Found
    </Typography>
  );
};

export default MeasurementList;
