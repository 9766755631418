import {createSlice} from "@reduxjs/toolkit";

const smartTrackingList = createSlice({
  name: "smartTrackingList",
  initialState: {
    loading: false,
    data: []
  },
  reducers: {
    getSmartTrackingPolygonListAction(state, action){
      return {
        loading: true,
        data: []
      }
    },
    setSmartTrackingPolygonDataAction(state, action){
      return {
        loading: false,
        data: action.payload.data
      }
    },
    updateSmartTrackingPolygonDataAction(state, action){
      void (state.data = [...state.data.filter(item => item.id !== action.payload.data.id), action.payload.data])
    }
  }
})

export const {getSmartTrackingPolygonListAction, setSmartTrackingPolygonDataAction, updateSmartTrackingPolygonDataAction} = smartTrackingList.actions
export default smartTrackingList.reducer