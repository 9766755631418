import { createSlice } from "@reduxjs/toolkit";

// const user = JSON.parse(localStorage.getItem("user"));

const LogoutSlice = createSlice({
  name: "logout",
  initialState: {
    loading: false,
    isLoggedIn: false,
    user: null,
  },
  reducers: {
    logoutAction: (state) => {
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
        user: null,
      };
    },
    logoutSuccess: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    logoutFailure: (state, action) => {
      return {
        ...state,
        loading: false,
        serverErrors: action.payload.errors,
      };
    },
  },
});

export const { logoutAction, logoutSuccess, logoutFailure } =
  LogoutSlice.actions;

export default LogoutSlice.reducer;
